
import {Config} from "../Config";
import {withRouter} from "react-router";
import React from "react";
import ShadowedImageCell from "../generics/ShadowedImageCell";

const OrteCell = ({ort, history}) => {

    const styles = {
        image: {
            width: '100%',
            height: 200,
            objectFit: 'cover'
        },
        card: {
            height: '100%',
            width: '100%'
        }

    };

    const keyVisual = ort.images[0] && ort.images[0].path;

    return (
        <div className='item'>
            <ShadowedImageCell
                title={ort.title}
                image={`${Config.baseUrl}/${keyVisual}`}
                style={styles.card}
                onClick={() => history.push(`/orte/${ort._id}`)}
            />
        </div>
    )
};


export default withRouter(OrteCell)
