import React, {Component} from 'react';
import {Config} from "../Config";
import './Mediathek.scss';
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {withRouter} from "react-router";

class MediathekDetail extends Component {

    state = {
        data: undefined
    };


    componentDidMount() {
        // Get data from api
        fetch(Config.endpoints.mediathek)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries.filter(it => it._id === this.props.id)[0]})
            })
    }

    render() {

        let content =
            <VerticalCenter style={{height: '100%'}}>
                <LoadingIndicator />
            </VerticalCenter>;


        if(this.state.data) {
            const item = this.state.data;
            content = (
                <div className='detail-content'>
                    <iframe class='iframeClass' src={`https://www.youtube.com/embed/${item.videoUrl}?o=U&noapp=1&playsinline=1`} webkit-playsinline="true" frameborder="0" width="100%" marginwidth="0" marginheight="0" hspace="0" vspace="0" scrolling="no" frameborder="0" />
                    <h1>{item.title}</h1>
                    <p dangerouslySetInnerHTML={{__html: item.content}}/>
                </div>
            )

        }

        return (
            <>
                <Header toggleNavigation={() => this.props.toggle()} title='Mediathek'/>;
                <div className='mediathek'>
                    <div className='content-container'>
                        <BackButton onClick={() => this.props.history.goBack()}/>
                        <div className='content'>
                            {content}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(MediathekDetail);
