import React, {Component} from 'react';
import './Ansprechpartner.scss';
import {Config} from "../Config";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {withRouter} from "react-router";

class AnsprechpartnerDetail extends Component {

    state = {
        filter: undefined,
        data: []
    };

    componentDidMount() {
        // Get ansprechpartner from api
        fetch(Config.endpoints.ansprechpartner)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries.filter(it => it._id === this.props.id)[0]})
            })
    }

    generateContent = () => {

        const content = this.state.data[this.props.type];
        const title = {
            "pfarrbuero": "Pfarrbüro",
            "seelsorger": "Seelsorger",
            //"kirchenvorstand": "Kirchenvorstand"
        };

        return (
            <>
                <h1 style={{fontSize: '22px'}}>
                    {title[this.props.type]}
                </h1>
                <p dangerouslySetInnerHTML={{__html: content}}/>
            </>
        )
    };

    render() {

        let content =
            <VerticalCenter style={{height: '100%'}}>
                <LoadingIndicator/>
            </VerticalCenter>;


        if (this.state.data) {
            content = this.generateContent();
        }

        const styles = {
            container: {
                padding: 32,
                paddingTop: 32
            },
        };

        return (
            <div style={styles.container}>
                <Header toggleNavigation={() => this.props.toggle()} title='Ansprechpartner'/>
                <div className='content-container'>
                    <BackButton onClick={() => this.props.history.goBack()}/>
                    <div className='content'>
                        {content}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AnsprechpartnerDetail);
