
const Config = {
  "baseUrl": 'https://admin.katholisch-hamm.de',
  "endpoints": {
    "headerImages": "https://admin.katholisch-hamm.de/api/collections/get/HeaderBilder?token=1d313896caef31a576bc9bda1c78dd",
    "headerTitle": "https://admin.katholisch-hamm.de/api/collections/get/SpruchDesTages?token=1d313896caef31a576bc9bda1c78dd",
    "aktuelles": "https://admin.katholisch-hamm.de/api/collections/get/Aktuelles?token=1d313896caef31a576bc9bda1c78dd",
    "pfarrnachrichten": "https://admin.katholisch-hamm.de/api/collections/get/Nachrichten?token=1d313896caef31a576bc9bda1c78dd",
    "veranstaltungen": "https://admin.katholisch-hamm.de/api/collections/get/Veranstaltungen?token=1d313896caef31a576bc9bda1c78dd",
    "orte": "https://admin.katholisch-hamm.de/api/collections/get/Orte?token=1d313896caef31a576bc9bda1c78dd",
    "mediathek": "https://admin.katholisch-hamm.de/api/collections/get/Mediathek?token=1d313896caef31a576bc9bda1c78dd",
    "ansprechpartner": "https://admin.katholisch-hamm.de/api/collections/get/Pfarrereien?token=1d313896caef31a576bc9bda1c78dd",
    "impressum": "https://admin.katholisch-hamm.de/api/singletons/get/Impressum?token=1d313896caef31a576bc9bda1c78dd",
    "datenschutz": "https://admin.katholisch-hamm.de/api/singletons/get/Datenschutz?token=1d313896caef31a576bc9bda1c78dd"
  }
};

const Colors = {

  default: '#5A5A5A',
  highlight: '#E7324F'

};

export { Config, Colors }