
import React from "react";
import {withRouter} from "react-router";

const VeranstaltungenCell = ({event, history}) => {

    const styles = {
        subtitle: {
            fontSize: 12,
            padding: 0
        },
        rowContainer: {
            position: 'relative',
            width: '100%',
            fontSize: 20,
            margin: 4,
            marginLeft: 24,
            marginRight: 24,
            marginBottom: 8,
            padding: 16,
            boxShadow: '0 0 8px #00000016',
            cursor: 'pointer'
        }
    };

    const onClick = () => {
        console.log(event);
        history.push('/veranstaltungen/'+event._id)
    };

    return (
        <div className='row noMargin' onClick={onClick}>
            <div style={styles.rowContainer}>
                <div style={{width: '100%'}}>
                    {event.title}
                </div>
                <div style={styles.subtitle}>
                    {event.date}
                </div>
            </div>
        </div>
    )
};

export default withRouter(VeranstaltungenCell)