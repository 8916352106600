
import React from 'react'
import {withRouter} from "react-router";
import {Config} from "../Config";

const PfarrnachrichtenCell = ({news, history}) => {

    const Styles = {

        PfarrSubtitle: {
            fontSize: 12,
            padding: 0,
            color: '#5A5A5A'
        },

        RowContainer: {
            position: 'relative',
            width: '90%',
            fontSize: 19.8,
            padding: 16,
            marginRight: 24,
            boxShadow: '0 0 8px #00000016',
            marginBottom: 16,
        },

        Icon: {
          position: 'absolute',
          right: 16, top: "calc(50% - 12.5px)",
          width: 25
        }
    };

    return (
        <div className='row noMargin clickable' onClick={() => window.location = `${Config.baseUrl}/${news.file}`}>
            <div style={Styles.RowContainer}>
                <img src='/assets/images/download-icon.png' style={Styles.Icon} />
                <div style={{maxWidth: '100%', paddingRight: '18px'}}>
                    <p style={{hyphens: 'auto'}}>{news.title}</p>

                </div>
                <div style={Styles.PfarrSubtitle}>
                    {news.date}
                </div>
            </div>
        </div>
    )
};

export default withRouter(PfarrnachrichtenCell)
