
import React, {Component} from 'react';
import './App.css';
import SideNavigation from "./SideNavigation";
import {BrowserRouter as Router, Route} from "react-router-dom"
import Startseite from "./Startseite";
import Aktuelles from "./aktuelles/Aktuelles";
import {Switch} from "react-router";
import PfarrnachrichtenList from "./pfarrnachrichten/PfarrnachrichtenList";
import VeranstaltungenScreen from "./veranstaltungen/VeranstaltungenScreen";
import VeranstaltungenDetail from "./veranstaltungen/VeranstaltungenDetail";
import OrteList from "./pastorale-orte/OrteList";
import OrteDetail from "./pastorale-orte/OrteDetail";
import {StyleRoot} from "radium";
import Radium from "radium";
import MediathekRoot from "./mediathek/MediathekRoot";
import MediathekDetail from "./mediathek/MediathekDetail";
import AnsprechpartnerRoot from "./ansprechpartner/AnsprechpartnerRoot";
import AnsprechpartnerDetail from "./ansprechpartner/AnsprechpartnerDetail";
import Impressum from "./static-content/Impressum";
import Datenschutz from "./static-content/Datenschutz";

class App extends Component {

    state = {
        navigationIsOpen: false
    };

    render() {

        const toggleNavigation = () => {
            this.setState({navigationIsOpen: !this.state.navigationIsOpen})
        };

        const routes = [
            <Route path="/" exact={true} key="root" render={() => {
                return <Startseite toggle={() => toggleNavigation()}/>
            }}/>,

            <Route path="/aktuelles" exact={true} key="aktuelles" render={() => {
                return <VeranstaltungenScreen toggle={() => toggleNavigation()}/>
            }}/>,

            <Route path="/pfarrnachrichten" exact={true} key="pfarrnachrichten" render={() => {
                return <PfarrnachrichtenList toggle={() => toggleNavigation()}/>
            }}/>,

            <Route path="/veranstaltungen" exact={true} key="veranstaltungen" render={() => {
                return <Aktuelles toggle={() => toggleNavigation()}/>
            }}/>,
            <Route path="/veranstaltungen/:id" exact={true} key="veranstaltungenDetail" render={(route) => {
                return <VeranstaltungenDetail toggle={() => toggleNavigation()} id={route.match.params.id}/>
            }}/>,

            <Route path="/orte" exact={true} key="orte" render={() => {
                return <OrteList toggle={() => toggleNavigation()}/>
            }}/>,

            <Route path="/orte/:id" exact={true} key="orteDetail" render={(route) => {
                return <OrteDetail toggle={() => toggleNavigation()} id={route.match.params.id}/>
            }}/>,

            <Route path="/mediathek" exact={true} key="mediathek" render={() => {
                return <MediathekRoot toggle={() => toggleNavigation()}/>
            }}/>,

            <Route path="/mediathek/:id" exact={true} key="mediathekDetail" render={(route) => {
                return <MediathekDetail toggle={() => toggleNavigation()} id={route.match.params.id}/>
            }}/>,

            <Route path="/ansprechpartner" exact={true} key="ansprechpartner" render={(route) => {
                return <AnsprechpartnerRoot toggle={() => toggleNavigation()}/>
            }}/>,

            <Route path="/ansprechpartner/:id/:type" exact={true} key="ansprechpartnerDetail" render={(route) => {
                return <AnsprechpartnerDetail toggle={() => toggleNavigation()} id={route.match.params.id}
                                              type={route.match.params.type}/>
            }}/>,

            <Route path="/impressum" exact={true} key="impressum" render={(route) => {
                return <Impressum toggle={() => toggleNavigation()}/>
            }}/>,

            <Route path="/datenschutz" exact={true} key="datenschutz" render={(route) => {
                return <Datenschutz toggle={() => toggleNavigation()}/>
            }}/>,
        ];

        if (!this.state) {
            return ""
        }

        return (
            <StyleRoot>
                <div className='App'>
                    <Router>
                        <div>
                            <SideNavigation
                                isOpen={this.state.navigationIsOpen}
                                toggle={() => this.setState({navigationIsOpen: !this.state.navigationIsOpen})}
                            />
                            <main className={this.state.navigationIsOpen ? 'openedNav' : 'closedNav'}>
                                <Switch>
                                    {routes}
                                </Switch>
                            </main>
                        </div>
                    </Router>
                </div>
            </StyleRoot>
        )
    }
}

export default Radium(App);
