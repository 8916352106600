
import React, {Component} from "react";
import TitleModule from "../modules/TitleModule"
import { Config } from "../Config";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import {withRouter} from "react-router";
import PfarrnachrichtenTable from "./PfarrnachrichtenTable";

const Styles = {

    Root: {
        paddingTop: 0,
    }
};

class PfarrnachrichtenModule extends Component {

    componentDidMount() {
        // Get aktuelles from api
        fetch(Config.endpoints.pfarrnachrichten)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries})
            })
    }

    render() {

        if(!this.state || !this.state.data) {
            return (
                <div style={Styles.Root}>
                    <TitleModule title='Pfarrnachrichten' showMore={true}/>
                    <VerticalCenter style={{height: '100%', paddingTop: 16}}>
                        <LoadingIndicator />
                    </VerticalCenter>
                </div>
            )
        }

        const {fullscreen} = this.props;
        const content = <PfarrnachrichtenTable data={this.state.data} fullscreen={fullscreen} />;
        const title = fullscreen ? '' : <TitleModule title='Pfarrnachrichten'
                                                     showMore={true}
                                                     onClick={() => this.props.history.push('/pfarrnachrichten')}/>;

        return (
            <div style={{...Styles.Root, ...this.props.style}} className='pfarrnachrichten-module'>
                {title}
                <div className='container-fluid noMargin'>
                    <div className='row noMargin clipping'>
                        {content}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PfarrnachrichtenModule)