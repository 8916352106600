
import React from 'react'
import Radium from 'radium';
import SideNavigationItem from "./SideNavigationItem";

const SideNavigation = ({isOpen, toggle}) => {

    const Style = {
        Root: {
            position: 'fixed',
            top: 0,
            height: '100%',
            width: 270,
            padding: 20,
            zIndex: 5,
            transition: 'right .5s',
            backgroundColor: 'white',
            paddingTop: 86,
            overflow: "scroll"
        },

        isOpen: {
            transition: 'right .5s',
            right: 0,
            boxShadow: '-5px 0px 5px 0px #00000016',
        },

        isClosed: {
            transition: 'right .5s',
            right: -270,
            boxShadow: '-5px 0px 5px 0px #00000000',
        }
    };

    return (
        <div style={{...Style.Root, ...(isOpen ? Style.isOpen : Style.isClosed)}}>
            <SideNavigationItem title='Startseite' icon='start' target='/' toggle={toggle} />
            <SideNavigationItem title='Aktuelles' icon='aktuelles' target='/aktuelles' toggle={toggle} />
            <SideNavigationItem title='Pfarrnachrichten' icon='nachrichten' target='/pfarrnachrichten' toggle={toggle} />
            <SideNavigationItem title='Veranstaltungen' icon='veranstaltungen' target='/veranstaltungen' toggle={toggle}/>
            <SideNavigationItem title='Pastorale Orte' icon='pastorale-orte' target='/orte' toggle={toggle}/>
            <SideNavigationItem title='Pfarreiensuche' icon='pfarrereien' toggle={toggle} onClick={() => window.location = "https://egvpb.maps.arcgis.com/apps/webappviewer/index.html?id=5fe7b0633b384b8eb3ab5a0dfc7b0c32" }/>
            <SideNavigationItem title='Ansprechpartner' icon='ansprechpartner' target='/ansprechpartner' toggle={toggle}/>
            <SideNavigationItem title='Mediathek' icon='mediathek' target='/mediathek' toggle={toggle}/>
            <SideNavigationItem title='Impressum' icon='icon-impressum' toggle={toggle} target="/impressum" />
            <SideNavigationItem title='Datenschutz' icon='icon-datenschutz' toggle={toggle} target="/datenschutz" />
        </div>
    )
};

export default Radium(SideNavigation);
