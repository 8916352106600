import React, {Component} from 'react';
import './Mediathek.scss';
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {withRouter} from "react-router";
import {Config} from "../Config";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import ShadowedImageCell from "../generics/ShadowedImageCell";

class MediathekRoot extends Component {

    state = {
        filter: undefined,
        data: []
    };

    componentDidMount() {
        // Get mediathek from api
        fetch(Config.endpoints.mediathek)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries})
            })
    }

    generateContent = () => {

        return this.state.data.map(item => {
            return (
                <div className='item'>
                    <ShadowedImageCell
                        title={item.title}
                        subtitle={item.subtitle}
                        image={`${Config.baseUrl}${item.image && item.image.path}`}
                        style={{width: '100%', height: '100%'}}
                        onClick={() => this.props.history.push(`/mediathek/${item._id}`)}
                    />
                    <div className='media-overlay'>
                        <img src='/assets/images/icon-play.png' style={{height: 80}}/>
                    </div>
                </div>
            )
        })
    };

    render() {

        let content =
            <VerticalCenter style={{height: '100%'}}>
                <LoadingIndicator />
            </VerticalCenter>;


        if(this.state.data) {
            content = this.generateContent();
        }

        return (
            <>
                <Header toggleNavigation={() => this.props.toggle()} title='Mediathek'/>
                <div className='mediathek'>
                    <div className='content-container'>
                        <BackButton onClick={() => this.props.history.goBack()}/>
                        <div className='content'>
                            {content}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(MediathekRoot);