
import React, {Component} from 'react';
import Header from "../Header";
import BackButton from "../generics/BackButton";
import { Config, Colors } from "../Config";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import Lightbox from "react-images";

class OrteDetail extends Component {

    state = {
        lightboxIsOpen: false,
        currentImage: 0,
        data: undefined
    };

    openLightbox = (index) => {
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    };

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };
    componentDidMount() {
        // Get data from api
        fetch(Config.endpoints.orte)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries.filter(it => it._id === this.props.id)[0]})
            })
    }

    render() {

        const styles = {
            container: {
                padding: 32,
                paddingTop: 32
            },

            image: {
                width: '100%',
                maxHeight: 300,
                marginBottom: 16,
                objectFit: 'cover',
                cursor: "pointer"
            },

            title: {
                fontSize: 20,
                fontWeight: 500,
                color: Colors.default
            },
            subtitle: {
                fontSize: 15,
                color: Colors.default
            },
            content: {
                paddingTop: 8,
                fontSize: 15,
                color: Colors.default
            },
            link: {
                color: Colors.default,
                fontWeight: 'bold'
            }
        };

        let content =
            <VerticalCenter style={{height: '100%'}}>
                <LoadingIndicator />
            </VerticalCenter>;


        if(this.state.data) {
            const item = this.state.data;
            const imagePaths = (item.images && item.images.map(it => `${Config.baseUrl}/${it.path}`)) || []

            const keyVisual = imagePaths.slice(0,1);
            const galleryItems = imagePaths
                .slice(1,imagePaths.length)
                .map((it, idx) => {
                    return (
                        <div className='image-gallery-item'>
                            <img src={it} style={{cursor: "pointer"}} onClick={() => this.openLightbox(idx + 1)} />
                        </div>
                    );
                });

            content =
                <div style={styles.content}>
                    <img style={styles.image} src={keyVisual} onClick={() => this.openLightbox(0)}/>
                    <div style={styles.title}>{item.title}</div>
                    <div style={styles.subtitle}>{item.adress}</div>
                    <div style={styles.content}>
                        <p dangerouslySetInnerHTML={{__html: item.content}}/>
                    </div>
                    <div className='image-gallery-container'>
                        {galleryItems}
                    </div>

                    <Lightbox
                        images={imagePaths.map(it => { return {src: it}})}
                        currentImage={this.state.currentImage}
                        isOpen={this.state.lightboxIsOpen}
                        onClickNext={this.gotoNext}
                        onClickPrev={this.gotoPrevious}
                        showImageCount={false}
                        onClose={() => this.setState({lightboxIsOpen: false})}
                     />

                </div>;

        }

        return (
            <div>
                <Header toggleNavigation={() => this.props.toggle()} title='Pastorale Orte'/>
                <div style={styles.container}>
                    <BackButton/>
                    {content}
                </div>
            </div>
        );


    }
}

export default OrteDetail;
