
import React, {Component} from 'react';
import Header from "../Header";
import BackButton from "../generics/BackButton";
import { Config } from '../Config'
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import {withRouter} from "react-router";
import AktuellesDetail from "./AktuellesDetail";
import {searchQueries, updateSearchParams} from "../Extensions";
import Radium from "radium";
import moment from "moment";

const Style = {
    Content: {
        padding: 32,
        paddingTop: 32,
    },
    ContentContainer: {
      paddingTop: 16
    },
    ItemRoot: {
        height: 212,
        boxShadow: '0 0 5px #00000016',
        backgroundSize: 'cover',
        backgroundPosition: '50% 50%',
        marginBottom: 16,
        '@media (min-width: 500px)': {
            height: 350
        }
    },
    Title: {
        fontSize: 18,
        fontWeight: 100
    },
    SubTitle: {
        fontSize: 12,
        fontWeight: 100
    },
    TextContainer: {
        color: 'white',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',
        height: '100%',
        padding: 16,
        cursor: 'pointer'
    }
};

class Aktuelles extends Component {

    state = {
        selectedNews: undefined
    };

    handleBack = () => {
        if(this.state.selectedNews) {
            this.setState({selectedNews: undefined})
        } else {
            this.props.history.goBack()
        }
    };

    componentDidMount() {

        const id = searchQueries()['id'];

        // Get aktuelles from api
        fetch(Config.endpoints.aktuelles)
            .then(res => res.json())
            .then(res => {
                var entries = res.entries;

                entries = entries.sort((a, b) => {
                    return moment(a.date, 'DD.MM.YYYY').toDate() - moment(b.date, 'DD.MM.YYYY').toDate();
                });

                const selection = res.entries.filter(it => it._id === id)[0];
                this.setState({data: entries, selectedNews: selection})
            })
    }

    render() {

        const header = <Header toggleNavigation={() => this.props.toggle() } title='Veranstaltungen' />

        // Handle loading state
        if(!this.state || !this.state.data) {
            return (
                <>
                    {header}
                    <div style={Style.Content}>
                        <VerticalCenter style={{height: '100%'}}>
                            <LoadingIndicator />
                        </VerticalCenter>
                    </div>
                </>
            )
        }


        let content = "";

        // Handle selection
        if(this.state.selectedNews) {
            content = <AktuellesDetail item={this.state.selectedNews} />
        }

        // Handle news items list
        else {
            content = this.state.data.map(item => {

                const keyVisual = `${Config.baseUrl}/${item.images && item.images[0] && item.images[0].path}`;
                return (
                    <div style={{...Style.ItemRoot, backgroundImage: `url(${keyVisual})`}} className='aktuelles-item' onClick={() =>  {
                        updateSearchParams(this, 'id', item._id);
                        this.setState({selectedNews: item})
                    }}>
                        <div className='darkenedBottom' style={Style.TextContainer}>
                        <span style={Style.SubTitle}>
                            {item.date}
                        </span>
                        <span style={Style.Title}>
                            {item.title}
                        </span>
                            <span style={Style.SubTitle}>
                            {item.subtitle}
                        </span>
                        </div>
                    </div>
                )
            });

            content =
                <div className='aktuelles-container'>
                    {content}
                </div>
        }

        return (
            <>
                {header}
                <div style={Style.Content}>
                    <BackButton onClick={() => this.handleBack()}/>
                    <div style={Style.ContentContainer} >
                        {content}
                    </div>
                </div>
            </>
        )
    }
}

export default Radium(withRouter(Aktuelles));