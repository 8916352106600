
import React from "react";
import './VerticalCenter.css'

export const VerticalCenter = ({children, style}) => {

    return (
        <div className="v-wrap" style={style}>
            <div className="v-box">
                {children}
            </div>
        </div>
    )
};

