import React, {Component} from 'react';
import HeaderImageCarousel from "./HeaderImageCarousel";
import AktuellesModule from "./aktuelles/AktuellesModule";
import PfarrnachrichtenModule from "./pfarrnachrichten/PfarrnachrichtenModule";
import VeranstaltungenModule from "./veranstaltungen/VeranstaltungenModule";
import Header from "./Header";

class Startseite extends Component {

    render() {

        return (
            <>
                <Header className='test' title={"Pastoraler Raum Hamm"} toggleNavigation={() => this.props.toggle()}/>
                <HeaderImageCarousel/>
                <VeranstaltungenModule/>
                <div className='startseite-flex-container'>
                    <PfarrnachrichtenModule/>
                    <AktuellesModule/>
                </div>
            </>
        )
    }
}

export default Startseite;
