
import {ClipLoader} from "react-spinners";
import React from "react";

const LoadingIndicator = ({containerStyle}) => {
    return (
        <div className="container" style={{...containerStyle, textAlign: 'center'}}>
            <ClipLoader
                sizeUnit={"px"}
                size={50}
                color="#E7324F"
            />
        </div>
    )
};

export default LoadingIndicator