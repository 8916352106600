import React, {Component} from 'react';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';
import './ImageCarousel.css'

const Style = {
    Root: {
        width: '100%',
        height: '100%'
    }
};

class ImageCarousel extends Component {

    render() {

        return (
            <div style={Style.Root}>
                <ImageGallery items={this.props.imageURLs.map(it => {
                    return {original: it}
                })}
                              showFullscreenButton={false}
                              showIndex={false}
                              showNav={false}
                              showPlayButton={false}
                              showThumbnails={false}
                              showBullets={true}
                              autoPlay={true}
                              slideInterval={3000}
                              slideDuration={1000}
                              disableSwipe={false}
                              renderItem={(item) => <img src={item.original}
                                                         style={{height: "420px"}} alt=""/>}
                />
            </div>
        )
    }
}


export default ImageCarousel;
