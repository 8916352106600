import React, {useEffect, useState} from 'react';
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {withRouter} from "react-router";
import {Config} from "../Config";

const Impressum = ({toggle, history}) => {

    const [content, setContent] = useState("");

    useEffect(() => {
        fetch(Config.endpoints.impressum)
            .then(data => {
                return data.json();
            })
            .then(json => {
                setContent(json.body)
            })
    }, [])

    return (
        <>
            <Header toggleNavigation={() => toggle()} title='Impressum'/>
            <div className='ansprechpartner'>
                <div className='content-container'>
                    <BackButton onClick={() => history.goBack()}/>
                    <div style={{paddingTop: 16}} dangerouslySetInnerHTML={{__html: content}} />
                </div>
            </div>
        </>
    );
};

export default withRouter(Impressum);