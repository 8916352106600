
import Radium from "radium";
import {withRouter} from "react-router";
import {VerticalCenter} from "./generics/VerticalCenter";
import React from "react";

const SideNavigationItem = ({title, icon, target, history, toggle, onClick}) => {

    const Style = {
        Row: {
            height: 60,
            paddingLeft: 0,
            cursor: 'pointer'
        },

        ContentContainer: {
            textAlign: 'left',
            borderBottom: '2px solid #5A5A5AAA'
        },

        Icon: {
            width: 40,
            paddingLeft: 8,
            float: 'left'
        },

        TitleContainer: {
            float: 'left',
            paddingLeft: 16
        },

        Title: {
            fontSize: 20,
            fontWeight: 200,
            color: '#5A5A5A'
        }
    };

    return (
        <div className='row' style={Style.Row} onClick={() => { onClick ? onClick() : history.push(target); toggle()}}>
            <div className='col-12'>
                <VerticalCenter style={Style.ContentContainer}>
                    <img src={`/assets/images/menu/${icon}.png`} style={Style.Icon}/>
                    <div style={Style.TitleContainer}>
                        <span style={Style.Title}>
                            {title}
                        </span>
                    </div>
                </VerticalCenter>
            </div>
        </div>
    )
};


export default withRouter(Radium(SideNavigationItem))