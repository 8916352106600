
import React, {Component} from 'react';
import './Header.scss'
import {withRouter} from "react-router";

class Header extends Component {
    render() {
        return (
            <div style={{height: 82}}>
                <div className='headerBar noMargin'>
                    <div className='content'>
                        <img src='/assets/images/logo_hamm.png' alt='Logo, Link zur Startseite' onClick={() => this.props.history.push('/')}/>
                        <span>{this.props.title}</span>
                        <img src='/assets/images/menu-icon.png' alt='Hamburger Menu' onClick={() => this.props.toggleNavigation()} />
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Header)

