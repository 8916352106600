import React from "react";
import {VerticalCenter} from "../generics/VerticalCenter";

const Styles = {

    Title: {
        paddingLeft: 24,
        color: '#E7324F',
        fontSize: 30,
        fontWeight: 400
    },

    More: {
        marginRight: 24,
        float: 'right',
        cursor: 'pointer'
    }
};

const TitleModule = ({title, showMore, onClick}) => {

    let more = "";
    if(showMore) {
        more = (
            <div className='col-2 noMargin'>
                <VerticalCenter>
                            <div style={Styles.More} onClick={onClick}>
                                Mehr
                            </div>
                </VerticalCenter>
            </div>
        )
    }

    return (
        <div className='container-fluid noMargin' style={{marginTop: 10}}>
            <div className='row noMargin'>
                <div className='col-10 noMargin'>
                    <h2 style={Styles.Title} onClick={onClick}>
                        {title}
                    </h2>
                </div>
                {more}
            </div>
        </div>
    )
};

export default TitleModule