
import React from 'react'
import PfarrnachrichtenModule from "./PfarrnachrichtenModule";
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {withRouter} from "react-router";

const styles = {
    content: {
        padding: 32,
        paddingTop: 32,
        paddingBottom: 0
    }
};

class PfarrnachrichtenList extends React.Component {

    render() {
        return (
            <>
                <Header toggleNavigation={() => this.props.toggle() } title='Pfarrnachrichten' />
                <div style={styles.content}>
                    <BackButton onClick={() => this.props.history.goBack()}/>
                </div>
                <PfarrnachrichtenModule fullscreen={true} style={{padding: 8, paddingTop: 15}}/>
            </>
        );
    }
}

export default withRouter(PfarrnachrichtenList)