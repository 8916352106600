
import React, {Component} from 'react';
import './AktuellesDetail.css'
import {Config} from "../Config";
import Lightbox from "react-images";

class AktuellesDetail extends Component {

    state = {
        lightboxIsOpen: false,
        currentImage: 0
    };

    openLightbox = (index) => {
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    };

    gotoPrevious = () => {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    };

    gotoNext = () => {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    };

    render() {

        const news = this.props.item;
        const images = [];
        news.images && news.images[0] && news.images[0].path && images.push(`${Config.baseUrl}/${news.images[0].path}`);
        news.images && news.images[1] && news.images[1].path && images.push(`${Config.baseUrl}/${news.images[1].path}`);
        news.images && news.images[2] && news.images[2].path && images.push(`${Config.baseUrl}/${news.images[2].path}`);

        console.log(images)
        return (
            <div style={this.props.style}>
                <div className='NewsDate'>
                    {news.date}
                </div>
                <div className='NewsTitle'>
                    {news.title}
                </div>
                <div>
                    <img src={images[0]} alt={'Key visual'} className='NewsKeyVisual' onClick={() => this.openLightbox(0)} />
                </div>
                <div className='NewsContent'>
                    <p dangerouslySetInnerHTML={{__html: news.content}} />
                </div>

                <Lightbox
                    images={images.map(it => { return {src: it}})}
                    currentImage={this.state.currentImage}
                    isOpen={this.state.lightboxIsOpen}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    showImageCount={false}
                    onClose={() => this.setState({lightboxIsOpen: false})}
                />

                <div className='NewsGalleryContainer'>
                    <div className='NewsGalleryItem' onClick={() => this.openLightbox(1)} >
                        { images.length >= 2 ? <img src={images[1]} className='NewsGalleryImage' /> : '' }
                    </div>
                    <div className='NewsGalleryItem' onClick={() => this.openLightbox(2)} >
                        { images.length >= 3 ? <img src={images[2]} className='NewsGalleryImage' /> : '' }
                    </div>
                </div>
            </div>
        )
    }
}

export default AktuellesDetail;