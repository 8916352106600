import React from "react";
import {searchQueries, updateSearchParams} from "../Extensions";
import {withRouter} from "react-router";
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {Config} from "../Config";
import OrteCell from "./OrteCell";
import './Orte.scss'

class OrteList extends React.Component {

    state = {
        filter: undefined,
        data: []
    };

    handleFilter = (filter) => {
        if (filter === 'Alle') {
            this.setState({filter: undefined});
            updateSearchParams(this, 'f', '')
        } else {
            this.setState({filter: filter})
            updateSearchParams(this, 'f', filter)
        }

    };

    componentDidMount() {
        this.setState({filter: searchQueries()['f']});

        // Get aktuelles from api
        fetch(Config.endpoints.orte)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries});

            })
    }


    render() {

        const style = {
            flexContainer: {
                height: 55,
                paddingLeft: 32,
                paddingRight: 32,
                marginTop: 32,
                display: 'flex',
                flexDirection: 'row'
            },
            filterTitle: {
                flexGrow: 1,
                textAlign: 'center',
                justifyContent: 'center',
                alignSelf: 'center'
            },
            filterButton: {
                float: 'right',
                position: 'relative'
            },
            filterButtonImage: {
                width: 20,
                height: 20
            },
            select: {
                opacity: 0.00001,
                position: 'absolute',
                top: '0',
                height: 50,
                width: 50,
                left: 0
            }
        };

        function compare( a, b ) {
          var nameA = a.title.toUpperCase(); // Groß-/Kleinschreibung ignorieren
          var nameB = b.title.toUpperCase(); // Groß-/Kleinschreibung ignorieren
          if ( nameA < nameB ){
            return -1;
          }
          if ( nameA > nameB ){
            return 1;
          }
          return 0;
        }

        this.state.data.sort(compare)


        const {filter} = this.state;
        const categories = ['Alle', 'Altenheime', 'Friedhöfe', 'Kindertageseinrichtungen', 'Kirchen', 'Krankenhäuser', 'Pfarrheime', 'Schulen',];
        const options = categories
            .map(it => <option>{it}</option>);


        let content = this.state.data
            .filter(it => filter ? it.category === filter : true);





        if(!content.length) {
            content = (
                <div style={{textAlign: 'center', width: '100%', fontSize: 15, paddingTop: 32}}>
                    Leider liegen keine Inhalte vor.
                </div>
            )
        } else {
            content = content.map(it => <OrteCell ort={it} />);
        }

        return (

            <>
                <Header toggleNavigation={() => this.props.toggle()} title='Pastorale Orte'/>
                <div className='orte'>


                    <div style={style.flexContainer}>
                        <BackButton onClick={() => this.props.history.goBack()}/>
                        <div style={style.filterTitle}>
                            {this.state.filter ? <>Gefiltert nach Kategorie<br/><b>{this.state.filter}</b></> : ''}
                        </div>
                        <BackButton image={'/assets/images/filter.png'}
                                    style={style.filterButton}
                                    imageStyle={style.filterButtonImage}
                                    onClick={() => undefined}>
                            <select className='custom-select' onChange={(e) => this.handleFilter(e.target.value)}>
                                {options}
                            </select>
                        </BackButton>

                    </div>

                    <div className='content'>
                        {content}
                    </div>

                </div>
            </>
        );
    }
}


export default withRouter(OrteList)
