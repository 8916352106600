import React from "react";
import PfarrnachrichtenCell from "./PfarrnachrichtenCell";
import moment from "moment";

const styles = {
    pfarrTitleContainer: {
        paddingLeft: 24,
        color: '#5A5A5A'
    },

    pfarrTitle: {
        fontSize: 20,
        color: '#5A5A5A',
        paddingBottom: 8,
        paddingTop: 16

    }
};

const PfarrnachrichtenBlock = ({pfarrerei}) => {

    const rows = pfarrerei.news.sort((a, b) => {
            return moment(b.date, 'DD.MM.YYYY').toDate() - moment(a.date, 'DD.MM.YYYY').toDate();
        })
        .map(news => {
            return <PfarrnachrichtenCell news={news}/>
        });

    return (
        <div className='row noMargin' style={styles.pfarrTitleContainer}>
                <span style={styles.pfarrTitle}>
                    {pfarrerei.name}
                </span>
            {rows}
        </div>
    )
};

export default PfarrnachrichtenBlock