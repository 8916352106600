
import React from "react";
import VeranstaltungenCell from "./VeranstaltungenCell";

const VeranstaltungenList = ({events}) => {

    const cells = events.map(event => {
        return <VeranstaltungenCell event={event}/>
    });

    // Handle error message
    let error = undefined;
    if(!cells.length) {
        error = (
            <div style={{textAlign: 'center', width: '100%', fontSize: 15, paddingTop: 32}}>
                Leider liegen keine Inhalte vor.
            </div>
        )
    }
    return (
        <div className='row noMargin clipping'>
            {cells}
            {error}
        </div>
    )
};


export default VeranstaltungenList