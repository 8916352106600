
import React, {Component} from "react";
import TitleModule from "../modules/TitleModule";
import {withRouter} from "react-router";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import {Config} from "../Config";
import ShadowedImageCell from "../generics/ShadowedImageCell";
import moment from "moment";

const Styles = {
    Node: {
        height: 380
    },
    Root: {
        margin: 0,
        padding: 0
    },
    More: {
        float: 'right',
        fontSize: 16,
        color: '#5A5A5A'
    },
    CardList: {
        paddingRight: 24,
        paddingLeft: 24
    }
};


class AktuellesModule extends Component {

    componentDidMount() {

        // Get aktuelles from api
        fetch(Config.endpoints.aktuelles)
            .then(res => res.json())
            .then(res => {
                var entries = res.entries.sort((a, b) => {
                    return moment(a.date, 'DD.MM.YYYY').toDate() - moment(b.date, 'DD.MM.YYYY').toDate();
                });
                this.setState({data: entries})
            })
    }

    render() {

        const title = <TitleModule title='Veranstaltungen' showMore={true} onClick={() => this.props.history.push('/veranstaltungen')}/>;

        // Handle loading state
        if(!this.state || !this.state.data) {
            return (
                <div style={Styles.Node}>
                    {title}
                    <div style={{height: '100%'}}>
                        <VerticalCenter style={{height: '100%'}}>
                            <LoadingIndicator />
                        </VerticalCenter>
                    </div>
                </div>
            )
        }

        const cards = this.state.data.map(item => {
            return <ShadowedImageCell date={item.date}
                                      image={`${Config.baseUrl}/${item.images && item.images[0] && item.images[0].path}`}
                                      title={item.title}
                                      subtitle={item.subtitle}
                                      style={{height: '100%'}}
                                      onClick={() => this.props.history.push(`/veranstaltungen?id=${item._id}`)} />
        });

        return (
            <div style={Styles.Node}>
                {title}
                <div className='container-fluid noMargin' style={Styles.Root}>
                    <div className='row noMargin clipping'>
                        <ul className='hs' style={Styles.CardList}>
                            {cards}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AktuellesModule)