
import React, {Component} from 'react';
import Header from "../Header";
import BackButton from "../generics/BackButton";
import { Config, Colors } from "../Config";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";

class VeranstaltungenDetail extends Component {

    state = {
        data: undefined
    };

    componentDidMount() {
        // Get data from api
        fetch(Config.endpoints.veranstaltungen)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries.filter(it => it._id === this.props.id)[0]})
            })
    }

    render() {

        const styles = {
            container: {
                padding: 32,
                paddingTop: 32
            },
            title: {
                fontSize: 20,
                fontWeight: 500,
                color: Colors.default
            },
            subtitle: {
                fontSize: 15,
                color: Colors.default
            },
            content: {
                paddingTop: 16,
                fontSize: 15,
                color: Colors.default
            }
        };

        let content =
                <VerticalCenter style={{height: '100%'}}>
                    <LoadingIndicator />
                </VerticalCenter>;


        if(this.state.data) {
            const item = this.state.data;
            content =
                <div style={styles.content}>
                    <div style={styles.title}>{item.title}</div>
                    <div style={styles.subtitle}>Datum: {item.date}</div>
                    <div style={styles.subtitle}>Ort: {item.location}</div>
                    <div style={styles.subtitle}>Kategorie: {item.category}</div>
                    <div style={styles.content}>
                        <p dangerouslySetInnerHTML={{__html: item.content}}/>
                    </div>

                </div>;

        }

        return (
            <div>
                <Header toggleNavigation={() => this.props.toggle()} title='Veranstaltungen'/>
                <div style={styles.container}>
                    <BackButton/>
                    {content}
                </div>
            </div>
        );


    }
}

export default VeranstaltungenDetail;