
export const searchQueries = () => {
    let searchQuerires = new URLSearchParams(window.location.search).entries()
    let params = {}
    for(let it of searchQuerires) {
        params[it[0]] = it[1]
    }
    return params
};

export const getByKeyPath = (keyPath, object, defaultValue) => {

    const result = keyPath.split('.').reduce((previous, current) => {
        if(!previous) {
            return undefined
        }
        return previous[current]
    }, object)

    return result || defaultValue
};

export const updateSearchParams = (ctx, key, value) => {
    let parameters = searchQueries()
    parameters[key] = value
    ctx.props.history.replace({
        pathname: window.location.pathname,
        search: `?${new URLSearchParams(parameters).toString()}`
    })
}