import React from "react";
import ImageCarousel from "./ImageCarousel";
import Radium from "radium";
import {Config} from "./Config";

const Styles = {

    Root: {
        marginBottom: 24,
        marginTop: -10,
        width: '100%'
    },

    DiagonalContainer: {
        marginTop: -40,
        width: '100%',
        backgroundImage: 'url(/assets/images/image-header-background.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
        zIndex: 1
    },

    Carousel: {
        overflow: 'hidden',
        height: 250,
        '@media screen and (min-width: 700px)': {
            height: 400
        }
    },

    ContentContainer: {
        padding: 24,
        paddingTop: 50,
        paddingBottom: 50,
        textAlign: 'center'
    },

    QuoteBackground: {

        position: 'relative',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%'
    },

    Quote: {
        fontFamily: 'Waiting for the Sunrise',
        fontSize: '1.3em',
        color: 'white'
    },

    QuoteSubtitle: {
        color: 'white',
        textAlign: 'right',
        fontWeight: 200,
        paddingRight: 24,
        fontSize: 14
    }
};

class HeaderImageCarousel extends React.Component  {

    state = {
        images: []
    };

    componentDidMount() {
        // Get images from api
        fetch(Config.endpoints.headerImages)
            .then(res => res.json())
            .then(res => {

                console.log(res.entries)
                this.setState({images: res.entries.map(it => `${Config.baseUrl}/${it.bild.path}`)})
            });

        // Get title from api
        fetch(Config.endpoints.headerTitle)
            .then(res => res.json())
            .then(res => {
                const entry = res && res.entries && res.entries[0];
                this.setState({title: entry});
            })
    }

    render() {

        return (

            <div style={Styles.Root}>
                <div className='row noMargin'>
                    <div className='noMargin' style={Styles.Carousel}>
                        <ImageCarousel imageURLs={this.state.images}/>
                    </div>
                </div>

                <div className='row noMargin' style={{marginTop: -15}}>
                    <div style={Styles.DiagonalContainer}>
                        <div style={Styles.ContentContainer}>
                        <span style={Styles.Quote}>
                            "{this.state.title && this.state.title.text}"
                        </span>
                            <br/>
                            <div style={{textAlign: 'right'}}>
                            <span style={Styles.QuoteSubtitle}>
                                {this.state.title && this.state.title.quelle}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

export default Radium(HeaderImageCarousel)
