import React from "react";
import TitleModule from "../modules/TitleModule"
import { Config } from "../Config";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import {withRouter} from "react-router";
import VeranstaltungenList from "./VeranstaltungenList";
import moment from "moment";

const Styles = {

    Root: {
        paddingTop: 24,
        marginBottom: 24,
    },

    ContentContainer: {
    },

    TitleContainer: {
        paddingLeft: 24,
        color: '#5A5A5A'
    },

    Title: {
        fontSize: 20,
        color: '#434141',
        paddingBottom: 8
    }
};


class VeranstaltungenModule extends React.Component {

    state = {
        data: undefined
    };

    componentDidMount() {
        // Get data from api
        fetch(Config.endpoints.veranstaltungen)
            .then(res => res.json())
            .then(res => {

                let entries = res.entries;
                if(entries) {
                    // Filter events that are history and sort by date
                    entries = entries.filter(it => {
                        return moment(it.date, 'DD.MM.YYYY').isAfter(moment());
                    }).sort((a, b) => {
                        return moment(a.date, 'DD.MM.YYYY').toDate() - moment(b.date, 'DD.MM.YYYY').toDate();
                    });
                    this.setState({data: entries})
                }
            })
    }

    loadMore = () => {
        this.props.history.push('/aktuelles')
    };

    render() {

        // Define the loading indicator
        let content = (
                <VerticalCenter style={{padding: 32}}>
                    <LoadingIndicator />
                </VerticalCenter>
            );

        // Replace loading indicator with data, if available
        if(this.state.data) {

            let filteredData = this.state.data;
            if(this.props.filter) {
                filteredData = filteredData.filter(it => it.category === this.props.filter);
            }
            if(this.props.limit) {
                filteredData = filteredData.slice(0,this.props.limit);
            }

            content = (
                <div className='container-fluid noMargin' style={Styles.ContentContainer}>
                    <VeranstaltungenList events={filteredData} />
                </div>
            )
        }


        let header = <TitleModule title='Aktuelles' showMore={true} onClick={() => this.loadMore()}/>;
        if(this.props.fullscreen) {
            header = undefined
        }

        // Final render
        return (
            <div style={{...Styles.Root, ...this.props.style}} className='veranstaltungen-module'>
                {header}
                {content}
            </div>
        )
    }
}

export default withRouter(VeranstaltungenModule)