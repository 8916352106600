
import React from "react";
import PfarrnachrichtenBlock from "./PfarrnachrichtenBlock";
import moment from "moment"
import _ from "lodash"

const PfarrnachrichtenTable = ({data, fullscreen}) => {

    // Sort plain list of news to pfarrerein/categories
    let pfarrerein = [];
    data = _.sortBy(data, function(o) { return new moment(o.date).format('YYYYMMDD'); }).reverse()

    console.log(data)
    
    data.forEach(it => {
        if(!pfarrerein[it.pfarrerei]) {
            pfarrerein[it.pfarrerei] = []
        }

        // Only append one pro category
        if(fullscreen || pfarrerein[it.pfarrerei].length === 0) {
            pfarrerein[it.pfarrerei].push(it)
        }
    });

    return Object.keys(pfarrerein).map(pfarrerei => {
        return <PfarrnachrichtenBlock pfarrerei={{name: pfarrerei, news: pfarrerein[pfarrerei]}} />
    });
};

export default PfarrnachrichtenTable
