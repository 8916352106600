
import Radium from "radium";
import {withRouter} from "react-router";
import React from "react";
import {VerticalCenter} from "./VerticalCenter";

const BackButton = ({history, onClick, image = '/assets/images/menu/back.png', imageStyle, style, children}) => {

    const Style = {
        Root: {
            width: 50,
            height: 50,
            backgroundColor: 'white',
            marginBottom: 16,
            boxShadow: '0px 0px 10px #00000020',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: '#E7324F32'
            }
        },
        ImageContainer: {
            paddingLeft: 4
        },
    };

    return (
        <div style={{...Style.Root, ...style}} onClick={() => onClick ? onClick() : history.goBack()}>
            <VerticalCenter style={Style.ImageContainer}>
                <img src={image} style={{...imageStyle, height: 20}} />
                {children}
            </VerticalCenter>
        </div>
    )
};


export default withRouter(Radium(BackButton))