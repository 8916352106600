
import React from "react";

const ShadowedImageCell = ({date, title, subtitle, image, onClick, style}) => {

    const Style = {
        Title: {
            fontSize: 18,
            color: 'white',
            fontWeight: 100
        },

        SubTitle: {
            fontSize: 12,
            fontWeight: 100,
            color: 'white',
        },

        ItemRoot: {
            height: 212,
            boxShadow: '0 0 5px #00000016',
            backgroundSize: 'cover'
        },

        TextContainer: {
            color: 'white',
            display: 'flex',
            justifyContent: 'flex-end',
            flexDirection: 'column',
            height: '100%',
            padding: 16,
            cursor: 'pointer'
        }
    };

    return (
        <div style={{...Style.ItemRoot, ...style, backgroundImage: `url(${image})`, backgroundPosition: '50% 80%'}} onClick={() => onClick()} >
            <div className='darkenedBottom' style={Style.TextContainer}>
                <span style={Style.SubTitle}>
                    {date}
                </span>
                    <span style={Style.Title}>
                        {title}
                    </span>
                <span style={Style.SubTitle}>
                     {subtitle}
                </span>
            </div>
        </div>
    )
};

export default ShadowedImageCell