import React from 'react'
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {withRouter} from "react-router";
import VeranstaltungenModule from "./VeranstaltungenModule";
import {searchQueries, updateSearchParams} from "../Extensions";

const styles = {
    content: {
        paddingTop: 28,
        flexDirection: 'row',
        paddingBottom: 16,
        flex: 1
    }
};

class VeranstaltungenScreen extends React.Component {

    state = {
        filter: undefined
    };

    handleFilter = (filter) => {
        if (filter === 'Alle') {
            this.setState({filter: undefined});
            updateSearchParams(this, 'f', '')
        } else {
            this.setState({filter: filter})
            updateSearchParams(this, 'f', filter)
        }
    };

    componentDidMount() {
        this.setState({filter: searchQueries()['f']})
    }

    render() {

        const style = {
            flexContainer: {
                height: 55,
                paddingLeft: 32,
                paddingRight: 32,
                marginTop: 32,
                display: 'flex',
                flexDirection: 'row'
            },
            filterTitle: {
                flexGrow: 1,
                textAlign: 'center',
                justifyContent: 'center',
                alignSelf: 'center'
            },
            filterButton: {
                float: 'right',
                position: 'relative'
            },
            filterButtonImage: {
                width: 20,
                height: 20
            },
            select: {
                opacity: 0.00001,
                position: 'absolute',
                top: '0',
                height: 50,
                width: 50,
                left: 0
            }
        };

        const categories = ['Alle', 'Auch bei uns...', 'Kirchenmusik', 'Spirituelles'];
        const options = categories.map(it => <option>{it}</option>);

        return (
            <>
                <Header toggleNavigation={() => this.props.toggle()} title='Aktuelles'/>

                <div style={style.flexContainer}>
                    <BackButton onClick={() => this.props.history.goBack()}/>

                    <div style={style.filterTitle}>
                        {this.state.filter ? <>Gefiltert nach Kategorie<br/><b>{this.state.filter}</b></> : ''}
                    </div>

                    <BackButton image={'/assets/images/filter.png'}
                                style={style.filterButton}
                                imageStyle={style.filterButtonImage}
                                onClick={() => undefined}>
                        <select style={style.select}
                                onChange={(e) => this.handleFilter(e.target.value)}>
                                {options}
                        </select>
                    </BackButton>
                </div>
                <div style={styles.content}>
                    <VeranstaltungenModule fullscreen={true}
                                           filter={this.state.filter}
                                           style={{paddingLeft: 8, paddingRight: 8, paddingTop: 0}}/>
                </div>

            </>
        );
    }
}

export default withRouter(VeranstaltungenScreen)