import React, {Component} from 'react';
import './Ansprechpartner.scss';
import {Config} from "../Config";
import {VerticalCenter} from "../generics/VerticalCenter";
import LoadingIndicator from "../generics/LoadingIndicator";
import Header from "../Header";
import BackButton from "../generics/BackButton";
import {withRouter} from "react-router";

class AnsprechpartnerRoot extends Component {

    state = {
        filter: undefined,
        data: []
    };

    componentDidMount() {
        // Get ansprechpartner from api
        fetch(Config.endpoints.ansprechpartner)
            .then(res => res.json())
            .then(res => {
                this.setState({data: res.entries})
            })
    }

    generateContent = () => {

        return this.state.data.map(item => {
            return (
                <div className='item-container'>
                    <h1>{item.name}</h1>
                    <div className='button-container'>
                        <div className='item-button' onClick={() => this.props.history.push(`ansprechpartner/${item._id}/pfarrbuero`)}>
                            <div>
                                Pfarrbüro
                            </div>
                            <img src={"assets/images/icon-arrow-right.png"} />
                        </div>
                        <div className='item-button' onClick={() => this.props.history.push(`ansprechpartner/${item._id}/seelsorger`)}>
                            <div>
                                Seelsorger
                            </div>
                            <img src={"assets/images/icon-arrow-right.png"} />
                        </div>
                        {/*<div className='item-button' onClick={() => this.props.history.push(`ansprechpartner/${item._id}/kirchenvorstand`)}>*/}
                        {/*    Kirchenvorstand*/}
                        {/*</div>*/}
                    </div>
                </div>
            )
        })
    };

    render() {

        let content =
            <VerticalCenter style={{height: '100%'}}>
                <LoadingIndicator />
            </VerticalCenter>;


        if(this.state.data) {
            content = this.generateContent();
        }

        return (
            <div>
                <Header toggleNavigation={() => this.props.toggle()} title='Ansprechpartner' />
                <div className='ansprechpartner'>

                    <div className='content-container'>
                        <BackButton onClick={() => this.props.history.goBack()}/>
                        <div className='content'>
                            {content}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(AnsprechpartnerRoot);